<template>
    <div class="container">
        <Y-header></Y-header>
        <div class="body-container">
            <div class="blocker"></div>
            <banner :data="bannerData" height="670px" :speed="bannerSpeed"></banner>

            <div class="industry-container">
                <center-title text="在专业领域深耕结果" bold font-size="42" position="center"></center-title>
                <center-title text="专业执行服务，为企业提供最全面最实用的智慧平台" bold font-size="20" color="#808080" position="center"></center-title>
                <img class="bg" src="@/assets/图片@2x.png"/>

                <div class="content-box">
                    <div class="box-left">
                        <div class="left-item" :class="[activeCard==i?'active':'']"   v-for="(item,i) in resolveData" :key="i" v-if="i<2" @click="handleClickCard(item,i)">
                            <img class="item-bg" :src="item.src"/>
                            <div class="texts">
                                <img class="icon" :src="item.icon"/>
                                <p class="item-name">{{item.name}}</p>
<!--                                <p class="item-text">{{item.planIntroduce}}</p>-->
                            </div>
                        </div>
                    </div>
                    <div class="box-right">
                        <div class="right-item" :class="[activeCard==i?'active':'']"  v-for="(item,i) in resolveData" :key="i" v-if="i>1 && i <8" @click="handleClickCard(item,i)">
                            <img class="item-bg" :src="item.src"/>
                            <div class="texts">
                                <img class="icon" :src="item.icon"/>
                                <p class="item-name">{{item.name}}</p>
<!--                                <p class="item-text">{{item.planIntroduce}}</p>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="issue-container">
                <div class="bbox">
                    <center-title text="行业痛点应对方案" bold font-size="42" position="center"></center-title>
                    <center-title text="独创具有唯一性的技术解决方案，提供全新贴合服务" bold font-size="20" color="#808080" position="center"></center-title>
                    <img class="bg-icon" src="@/assets/装饰@2x.png"/>
                    <img class="bg-icon2" src="@/assets/装饰@2x.png"/>
                    <img class="icon" src="@/assets/看1@2x.png"/>

                    <div class="button-tabs-box">
                        <div class="tab-button" :class="[active == i ? 'active':'']" v-for="(item,i) in issueData" :key="i" @click="clickPoint(item,i)">
                            <div class="issue-name" style="margin: 0;font-size: 16px">行业痛点{{i+1}}</div>
                            <div class="issue-name" style="font-weight:600;">{{item.painPoint}}</div>
<!--                            <div>{{item.desc}}</div>-->
                        </div>
                    </div>
                    <div class="issue-content-box">
                        <div class="content-box-text">
                            <div class="text-box">
                                <div>痛点概述</div>
                                <p>{{issueContent}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="solution-container">
                <center-title text="亮点解决方案" bold font-size="42" position="center"></center-title>
                <center-title text="独创具有唯一性的技术解决方案，提供全新贴合服务" bold font-size="20" color="#808080" position="center"></center-title>
                <div class="card-box">
                    <a-card hoverable style="width: 310px;" v-for="(item,i) in solveData" :key="i">
                        <img slot="cover" :src="item.cover" style="height: 200px;object-fit: cover"/>
                        <a-card-meta :title="i==0?item.name:i==1?'实施内容':i==2?'应用场景':'方案描述'">
                            <template slot="description">
                                <p class="description">{{item.text}}</p>
                            </template>
                        </a-card-meta>
<!--                        <div class="card-link">-->
<!--                            <a-icon type="arrow-right" />-->
<!--                            <span>了解详情</span>-->
<!--                        </div>-->
                    </a-card>
                </div>

            </div>
        </div>
        <Y-footer></Y-footer>
        <a-back-top/>
    </div>
</template>

<script>
import Banner from "@/components/banner.vue";
import CenterTitle from "@/components/centerTitle.vue";
import CrossCard from "@/components/crossCard.vue";
import footer from "@/components/footer.vue";
import header from "@/components/header.vue";
import {bannerData, selectSolution} from "@/api/apis";
import handleRequest from "@/utils/handleRequest";
export default {
    name: "solve",
    components: {
        CrossCard,
        CenterTitle,
        Banner,
        'Y-footer':footer,
        'Y-header':header,
    },
    data() {
        return {
            bannerSpeed:2000,
            bannerData:[],
            sceneData:[],
            solveData:[],
            issueData:[],
            resolveData:[],
            active:0,
            curData:{},
            issueContent:'',
            activeCard:0
        }
    },
    mounted() {
        this.getBannerData();
        this.getSceneData();

    },
    methods: {
        clickPoint(item,i){
            this.active = i
            this.issueContent = this.issueData[i].painPointContent
        },
        handleClickCard(data,i){
            this.activeCard = i
            this.curData = data
            this.issueData = data.scenario
            this.issueContent = this.issueData[0].painPointContent
            console.log(data)
            let cards
            cards = [
                {
                    cover:require('@/assets/IMG_5569@2x.png'),
                    name:data.planName,
                    text:data.planIntroduce
                },
                {
                    cover:require('@/assets/物联网大数据@2x.png'),
                    name:data.planName,
                    text:data.execute
                },
                {
                    cover:require('@/assets/物联网@2x.png'),
                    name:data.planName,
                    text:data.application
                }
            ]
            this.solveData = cards
            console.log(this.solveData)
        },
        async getBannerData(){
            const data = handleRequest(await bannerData({type:'解决方案',state:1}))
            let banner = data.content
            banner.forEach(item=>{
                item.img = JSON.parse(item.img)[0]
            })
            this.bannerSpeed = (banner[0]?.second*1000) || this.bannerSpeed
            this.bannerData = banner
        },
        async getSceneData(){
            const data = [
                {
                    icon:require('@/assets/科技@2x.png'),
                    src:require('@/assets/小图1@2x.png'),
                },
                {
                    icon:require('@/assets/科技 (2) 2@2x.png'),
                    src:require('@/assets/小图1@2x.png'),
                },
                {
                    icon:require('@/assets/icon1.png'),
                    src:require('@/assets/square1.png'),
                },
                {
                    icon:require('@/assets/icon2.png'),
                    src:require('@/assets/square2.png'),

                },
                {
                    icon:require('@/assets/icon3.png'),
                    src:require('@/assets/square3.png'),
                },
                {
                    icon:require('@/assets/标3@2x.png'),
                    src:require('@/assets/square4.png'),
                },
                {
                    icon:require('@/assets/标4@2x.png'),
                    src:require('@/assets/square5.png'),
                },
                {
                    icon:require('@/assets/科技备份 3@2x.png'),
                    src:require('@/assets/square6.png'),
                },
            ]
            const res = handleRequest(await selectSolution({state:1}))
            this.resolveData = res.content
            this.resolveData.forEach((item,i)=>{
                item.applicationImg = JSON.parse(item.applicationImg)
                item.executeImg = JSON.parse(item.executeImg)
                item.planImg = JSON.parse(item.planImg)
                item.scenario = JSON.parse(item.scenario)
                item.icon = data[i].icon
                item.src = data[i].src
            })
            this.issueData = this.resolveData[0].scenario
            this.curData = this.resolveData[0]
            this.issueContent = this.curData.scenario[0].painPointContent
            this.solveData = [
                {
                    cover:require('@/assets/IMG_5569@2x.png'),
                    name:this.curData.planName,
                    text:this.curData.planIntroduce
                },
                {
                    cover:require('@/assets/物联网大数据@2x.png'),
                    name:this.curData.name,
                    text:this.curData.execute
                },
                {
                    cover:require('@/assets/物联网@2x.png'),
                    name:this.curData.name,
                    text:this.curData.application
                }
            ]
        },
    },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 1768px){
    .industry-container{
        height: 800px;
        padding-top:60px;
        background-image: url("../assets/背景2@2x.png");
        background-size: 100%;
        position: relative;
        //由于UI不想把背景图和元素图切到一张图上只能自己定位
        .bg{
            position: absolute;
            bottom: 0;
            width: 1200px;
            left: calc(50% - 1200px /2);
        }
        .content-box{
            z-index: 10;
            position: sticky;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 !important;
            .icon{
                margin-top: 85px;
                margin-bottom: 10px;
                width: 28px;
            }
            .active{
                box-shadow: 5px 0px 100px 20px #409EFF;
            }
            .box-left{
                width:30rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .left-item{
                    margin: 5px;
                    width:30rem;
                    height: 240px;
                    color: #FFFFFF;
                    position: relative;
                    border-radius: 16px;
                    overflow: hidden;
                    transition: all .5s ease-in-out;
                    &:hover{
                        transform:translateY(-2%);
                        box-shadow: 3px 3px 20px 10px #c0c0c0;
                    }
                    .item-bg{
                        position: absolute;
                        width: 100%;
                        height: 100%;

                    }
                    .texts{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        padding: 30px;
                        .item-name{
                            font-size: 23px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 500;
                            margin-bottom: 10px;
                            color: #FFFFFF;
                        }
                        .item-text{
                            font-size: 16px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 34px;
                            height: 73px;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            margin: 0;
                            display: -webkit-box;
                            -webkit-box-orient:vertical;
                            -webkit-line-clamp: 2;

                        }
                    }

                }

            }
            .box-right{
                display:flex;
                flex-wrap: wrap;
                width: 720px;
                .right-item{
                    margin: 5px;
                    width: 240px;
                    height: 240px;
                    color: #FFFFFF;
                    position: relative;
                    border-radius: 16px;
                    overflow: hidden;
                    transition: all .5s ease-in-out;
                    &:hover{
                        transform:translateY(-2%);
                        box-shadow: 3px 3px 20px 10px #c0c0c0;

                    }
                    .item-bg{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                    .texts{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        padding: 30px;
                        .icon{
                            margin-top: 90px;
                        }
                        .item-name{
                            font-size: 23px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 500;
                            margin-bottom: 10px;
                            color: #FFFFFF;
                        }
                        .item-text{
                            font-size: 16px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 34px;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            height: 200px;
                            margin: 0;
                            display: -webkit-box;
                            -webkit-box-orient:vertical;
                            -webkit-line-clamp: 6;

                        }
                    }

                }


            }

        }
    }
    .solution-container{
        margin: 0 165px !important;
        padding: 40px 0;
        .card-box{
            display: flex;
            justify-content: space-between;
            .description{
                line-height: 25px;

                //height: 110px;
                //text-overflow: ellipsis;
                //display: -webkit-box;
                //-webkit-line-clamp: 6;
                //-webkit-box-orient: vertical;
            }
            .card-link{
                margin-top: 10px;
            }
        }
    }

}
.container{
    .body-container{
        background-color:#FFFFFF;
        .blocker{
            height: 92px;
        }
        .industry-container{
            height: 800px;
            padding-top:60px;
            background-image: url("../assets/背景2@2x.png");
            background-size: 100%;
            position: relative;
            //由于UI不想把背景图和元素图切到一张图上只能自己定位
            .bg{
                position: absolute;
                bottom: 0;
                width: 1200px;
                left: calc(50% - 1200px /2);
            }
            .content-box{
                z-index: 10;
                position: sticky;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 260px;
                .icon{
                    margin-top: 85px;
                    margin-bottom: 10px;
                    width: 28px;
                }
                .active{
                    box-shadow: 5px 0px 100px 20px #409EFF;
                }
                .box-left{
                    width:30rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .left-item{
                        margin: 5px;
                        width:30rem;
                        height: 240px;
                        color: #FFFFFF;
                        position: relative;
                        border-radius: 16px;
                        overflow: hidden;
                        transition: all .5s ease-in-out;
                        &:hover{
                            transform:translateY(-2%);
                            box-shadow: 3px 3px 20px 10px #c0c0c0;
                        }
                        .item-bg{
                            position: absolute;
                            width: 100%;
                            height: 100%;

                        }
                        .texts{
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            padding: 30px;
                            .item-name{
                                font-size: 23px;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 500;
                                margin-bottom: 10px;
                                color: #FFFFFF;
                            }
                            .item-text{
                                font-size: 16px;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 34px;
                                height: 73px;
                                overflow:hidden;
                                text-overflow:ellipsis;
                                margin: 0;
                                display: -webkit-box;
                                -webkit-box-orient:vertical;
                                -webkit-line-clamp: 2;

                            }
                        }

                    }
                }
                .box-right{
                    display:flex;
                    flex-wrap: wrap;
                    width: 750px;
                    .right-item{
                        margin: 5px;
                        width: 240px;
                        height: 240px;
                        color: #FFFFFF;
                        position: relative;
                        border-radius: 16px;
                        overflow: hidden;
                        transition: all .5s ease-in-out;
                        &:hover{
                            transform:translateY(-2%);
                            box-shadow: 3px 3px 20px 10px #c0c0c0;

                        }
                        .item-bg{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                        }
                        .texts{
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            padding: 30px;
                            .icon{
                                margin-top: 90px;
                            }
                            .item-name{
                                font-size: 23px;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 500;
                                margin-bottom: 10px;
                                color: #FFFFFF;
                            }
                            .item-text{
                                font-size: 16px;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 34px;
                                overflow:hidden;
                                text-overflow:ellipsis;
                                height: 200px;
                                margin: 0;
                                display: -webkit-box;
                                -webkit-box-orient:vertical;
                                -webkit-line-clamp: 6;

                            }
                        }

                    }

                }

            }
        }

        .issue-container{
            padding:40px 0;
            background-color: #F5F5F5;
            .bbox{
                margin: 0 362px;
                position: relative;
                .bg-icon{
                    position: absolute;
                    width: 220px;
                    right:-110px;
                    top: 80px;
                    z-index: 0;
                }
                .bg-icon2{
                    transform:rotate(53deg);
                    width: 220px;
                    position: absolute;
                    bottom: 20px;
                    left: -60px;
                }
                .icon{
                    position: absolute;
                    margin-top: 40px;
                    width: 148px;
                    height: 148px;
                    top: 240px;
                    left: -140px;
                }
                .button-tabs-box{
                    display: flex;
                    justify-content: space-between;

                    .active{
                        background-color: #3769FE !important;
                        color: #FFFFFF;
                    }
                    .tab-button{
                        z-index: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #FFFFFF;
                        border-radius: 16px;
                        box-shadow: 0 0 1px 2px #ececec;
                        width: 30%;
                        height: 100px;
                        text-align: center;
                        transition: all .5s ease-in-out;
                        font-weight: 400;
                        font-size: 16px;
                        padding: 10px;
                        .issue-name{
                            margin: 10px 0;
                            font-size: 18px;
                        }
                        &:hover{
                            background-color: #3769FE;
                            color: #FFFFFF;
                        }
                    }
                }

                .issue-content-box{
                    display: flex;
                    align-items: center;
                    padding: 50px 0;
                    position: relative;


                    .content-box-text{
                        .text-box{
                            z-index: 10;
                            div{
                                font-size: 22px;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 500;
                                color: #333333;
                                line-height: 32px;
                            }
                            p{
                                font-size: 16px;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 400;
                                color: #505050;
                                line-height: 34px;
                            }
                        }
                    }
                    .image{
                        width: 400px;
                    }
                }
            }

        }

        .solution-container{
            margin: 0 362px;
            padding: 40px 0;
            .card-box{
                display: flex;
                justify-content: space-between;
                .description{
                    line-height: 25px;
                    //height: 110px;
                    //text-overflow: ellipsis;
                    //display: -webkit-box;
                    //-webkit-line-clamp: 6;
                    //-webkit-box-orient: vertical;
                }
                .card-link{
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>
