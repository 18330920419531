<template>
    <div class="function-introduce-container">
        <div class="item" :class="[index%2==1 ? 'item direction':'item']">
            <div class="cover">
                <img  v-prlx="{  custom: true,limit: { min: 40, max: 60 } }" :src="src"/>
            </div>
            <div class="text-box">
                <div class="text">
                    <h3>{{title}}</h3>
                    <p>{{content}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "crossCard",
    props:['title','src','content','index']
}
</script>

<style scoped lang="scss">
.function-introduce-container{
    .direction{
        flex-direction: row-reverse;
    }
    .item{
        padding: 20px;
        display: flex;
        justify-content: center;
        margin: 20px;
        .cover{
            img{
                transform: scale(calc(var(--parallax-value) / 70 ));
                object-fit: cover;
                width: 360px;
                height: 260px;
            }
        }
        .text-box{
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h3{
                font-weight: 550;
            }
        }
    }
}
</style>
